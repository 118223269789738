import React, { useState, useEffect } from 'react';

function Footer({strings}) {
  const [rssFeeds, setRSS] = useState([]);

  useEffect(() => {
    async function fetchRssFeeds() {
      const response = await fetch('rss/index.xml');
      const text = await response.text();
      const parser = new DOMParser();
      const xml = parser.parseFromString(text, 'text/xml');
      const items = xml.getElementsByTagName("item")
      const maxAge = new Date()
      maxAge.setMonth(maxAge.getMonth()-1)
      let maxAgeTS = parseInt(Math.floor(maxAge.getTime() / 1000))
      let rssData = []
      for (let item of items) {
        let title = item.getElementsByTagName("title").item(0).innerHTML
        let filename = item.getElementsByTagName("filename").item(0).innerHTML
        let lastEventDate = parseInt(item.getElementsByTagName("lastEventDate").item(0).innerHTML)
        if (lastEventDate > maxAgeTS) {
          let data = {
            title:   title,
            filename: filename
          }
          rssData.push(data)
        }
      }
      setRSS(rssData);
    }
    fetchRssFeeds();

  }, []);


  // receiveData(passValueDown)
  const linkSection = (
    <p>
    <a href="https://www.gnistor.se/">gnistor.se</a><br></br>
    <a href="https://gatorna.info/">gatorna.info</a><br></br>
    <a href="https://radar.squat.net/">radar.squat.net</a><br></br>
    <a href="https://dukop.dk/">dukop.dk</a><br></br>
    </p>
  )
  const rssSection = rssFeeds.map((row, index) => (
    <a href={row.filename}>{row.title}<br></br></a>
  ));


  return (
    <div id="footer">
      <div className="footerOuterDiv">
        <div id="rssDiv">
          <h3 id="rssTitle">RSS</h3>
          <p id="rssFeeds">
            {rssSection}
          </p>
        </div>
      </div>
      <div className="footerCenterDiv">
        <div id="aboutDiv">
          <h3 id="aboutHeader">{strings.aboutHead}</h3>
          <p>
            {strings.aboutBody}
          </p>
          <p>
            {strings.grafikCred}<a href="https://www.ruhani.se/">Ruhani</a>.
          </p>
          {/* <p>
            <a href="https://nyhetsbrev.kamratpostaren.se/subscription/form">
              {strings.newsletter2}
            </a>
          </p>
          <p>
            {strings.twitter} <a href="https://twitter.com/kamratpostaren">Twitter</a>, <a href="https://radikal.social/@kamratpostaren">Mastodon</a> {strings.and} <a href="https://bsky.app/profile/kamratpostaren.bsky.social">Bluesky</a>!
          </p> */}
          <p>
            {strings.aboutContact}
          </p>
        </div>
        <div className="phone" id="moreInfoPhone">
          <div className="phoneInfo">
            <h3>RSS</h3>
            <p>
              {rssSection}
            </p>
          </div>
          <div className="phoneInfo" id="rssDiv">
            <h3>{strings.srcHead}</h3>
            {linkSection}
          </div>
        </div>
      </div>
      <div className="footerOuterDiv" id="sources">
        <div id="srcHeader">
          <h3>{strings.srcHead}</h3>
          {linkSection}
        </div>
      </div>
    </div>
  );
}

export default Footer;
