import React, { useState, useEffect } from 'react';
import getStrings from "./strings"

function PhoneSelections({passValueUp}) {
  const [cities, setCities] = useState([])
  const [lang, setLang] = useState('sv');
  const [selectedCity, setSelectedCity] = useState("");
  const [strings, setStrings] = useState(getStrings(lang));
  

  const setVariables = (varName, value) => {
    let data = {
      "varName": varName,
      "value": value
    }
    switch(varName) {
      case 'City':
        setSelectedCity(value)
        /* falls through */
      case 'lang':
        setLang(value)
        setStrings(getStrings(value))
        /* falls through */
      default:
        //Nothing
    }
    passValueUp([data])
  }

  useEffect(() => {
    async function fetchCities() {
      const response = await fetch('calendar.csv');
      const text = await response.text();
      let rows = text.split('\n');
      rows = rows.filter((str) => str !== '');
      let cities = rows.map(row => {
        const cells = row.split(',');
        let city = cells[3].replace(/&comma;/g, ",")
        return city
      });
      cities = Array.from(new Set(cities))
      cities.sort()
      cities.unshift("allCities")
      setCities(cities);
    }

    fetchCities()

  }, []);

  const dropdown = (
        <select
          id="citySelector"
          value={selectedCity}
          onChange={e => setVariables("City", e.target.value)}>
          <option value="" disabled hidden>{strings.selectCity}</option>
          {cities.map(row => {
            if (row === "allCities") {
              return <option value={row}>{strings.allCities}</option>
            } else {
              return <option value={row}>{row}</option>
            }
          })}
        </select>
    );
    
  const changeLang = () => {
    if (lang === "sv") {
      setVariables("lang", "eng")
    } else {
      setVariables("lang", "sv")
    }
  }

  return (
    <div id="phoneSelections">
      <div id="citySelectorDiv">
        {dropdown}
      </div>
      <div id="langDiv">
        <button id="langButton" type="button" onClick={() => changeLang()}>{strings.changeLang}</button>
      </div>
    </div>
  );
}

export default PhoneSelections