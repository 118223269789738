function getStrings(lang) {
  const svStrings = {
      calHeader: "Kommande händelser",
      timeHead: "När",
      eventHead: "Vad",
      locationHead: "Var",
      srcHead: "Källor",
      aboutHead: "Om Kamratpostaren",
      aboutBody: "Kamratpostaren är ett försök att samla alla Sveriges vänsterevenemang på ett ställe, så att det är lättare att hålla koll på vad som händer i svensk autonom vänster. Grundläggande för Kamratpostaren är att tanken inte är att ersätta existerande kalendrar - alla försök att skapa en ny gemensam standard kommer ofrånkomligen leda till att vi bara splittrar människor mellan ännu fler plattformar. Kamratpostaren kommer alltså inte att publicera några egna evenemang, utan hämtar enbart från redan existerande kalendrar, både för att göra det lättare att hitta evenemang, och att för att ge kalendrarna ytterligare spridning.",
      aboutContact: "Tankar, frågor och synpunkter skickas till info@kamratpostaren.se.",
      twitter: "Kamratpostaren finns också på ",
      and: "och",
      changeLang: "English",
      selectCity: "Välj stad",
      allCities: "Alla",
      howToButton: "Hur lägger jag upp ett evenemang?",
      howToText: "Evenemangen på Kamratpostaren hämtas automatiskt från externa källor, och det är därför inte möjligt att publicera evenemang direkt till Kamratpostaren. Istället behöver du publicera evenemangen på någon av kalendrarna som används som källor. En lista över de kalendrarna finns i sidfoten, och mer information om hur du publicerar ett evenemang finns på respektive hemsida.",  
      newsletter: "Nyhetsbrev",
      grafikCred: "Kamratpostarens grafik är designad av ",
  }
  const engStrings = {
      calHeader: "Upcoming events",
      timeHead: "When",
      eventHead: "What",
      locationHead: "Where",
      srcHead: "Sources",
      aboutHead: "About Kamratpostaren",
      aboutBody: "Kamratpostaren is an attempt to collect all of Sweden’s leftist events in one place, to make it easier to keep up with what’s going on in the autonomous left. The base idea with Kamratpostaren is that it is not meant to replace existing calendars - all attempts at creating a new common standard will inevitably lead to people being split between even more platforms. Kamratpostaren will not be posting any events of its own, using only events from previously existing calendar platforms, in the hope of both making it easier to find events, and spreading those platforms to no users.",
      aboutContact: "Thoughts, questions and ideas can be sent to info@kamratpostaren.se.",
      twitter: "Kamratpostaren is also on ",
      and: "and",
      changeLang: "Svenska",
      selectCity: "Select city",
      allCities: "All",
      howToButton: "How do I add an event?",
      howToText: "As Kamratpostaren is an event aggregation service, it is not possible to add events to the site directly. Instead you need to add events to one of the source calendars. You can find a list of sources in the site footer, and more information about adding events on each site.",
      newsletter: "Newsletter",
      grafikCred: "Kamratpostaren's graphics by ",
    }
  if (lang === "sv") {
    return svStrings;
  } else {
    return engStrings;
  }
}

export default getStrings