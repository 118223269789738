import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import './css/header.css';
import './css/body.css';
import './css/footer.css';
import './css/screen.css';
import Site from './Site';
import './fonts/Gilbert-Bold.otf';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Site />
  </React.StrictMode>
);


