import React, { useState, useEffect } from 'react';
import getStrings from "./strings"

function Header({passValueUp}) {
  const [cities, setCities] = useState([])
  const [lang, setLang] = useState('sv');
  const [selectedCity, setSelectedCity] = useState("");
  const [strings, setStrings] = useState(getStrings(lang));

  const setVariables = (varName, value) => {
    let data = {
      "varName": varName,
      "value": value
    }
    switch(varName) {
      case 'City':
        setSelectedCity(value)
        break;
        /* falls through */
      case 'lang':
        setLang(value)
        setStrings(getStrings(value))
        break;
        /* falls through */
      default:
        // Nothing
    }
    passValueUp([data])
  }

  useEffect(() => {
    async function fetchCities() {
      const response = await fetch('calendar.csv');
      const text = await response.text();
      let rows = text.split('\n');
      rows = rows.filter((str) => str !== '');
      let cities = rows.map(row => {
        const cells = row.split(',');
        let city = cells[3].replace(/&comma;/g, ",")
        return city
      });
      cities = Array.from(new Set(cities))
      cities = cities.filter(n => n)
      cities.sort()
      cities.unshift("allCities")
      setCities(cities);
    }

    fetchCities()

  }, []);

  const dropdown = (
        <select
          id="citySelector"
          value={selectedCity}
          onChange={e => setVariables("City", e.target.value)}>
          <option value="" disabled hidden>{strings.selectCity}</option>
          {cities.map(row => {
            if (row === "allCities") {
              return <option value={row}>{strings.allCities}</option>
            } else {
              return <option value={row}>{row}</option>
            }
          })}
        </select>
    );
    
  const changeLang = () => {
    if (lang === "sv") {
      setVariables("lang", "eng")
    } else {
      setVariables("lang", "sv")
    }
  }

  const togglePopup = () => {
    let data = {
      "varName": "popupToggle",
      "value": ""
    }
    passValueUp([data])
  }

  return (
      <div id="header">
        <div className="headerOuterDiv" id="headerLeft">
          <div id="pfpDiv">
            <img id="pfp" alt="Kamratpostarens logga" src="assets/sitebild.png"></img>
          </div>
          <div id="titleDiv">
            <h1 className="title" id="siteTitle">Kamratpostaren</h1>
          </div>
        </div>
        <div className="headerCenterDiv">
        <h3 id="HowToButton" onClick={() => togglePopup()}>{strings.howToButton}</h3>
          {/* <a className="nyhetsbrev" href="https://nyhetsbrev.kamratpostaren.se/subscription/form">
            <h3 className="nyhetsbrev" id="nyhetsbrev">{strings.newsletter}</h3>
          </a> */}
          {/* <h1 className="title" id="phoneTitle">Kamratpostaren</h1> */}
        </div>
        <div className="headerOuterDiv" id="headerRight">
          <div className="rSubSection" id="citySelectorDiv">
           {dropdown}
          </div>
          <div className="rSubSection" id="langDiv">
            <button id="langButton" type="button" onClick={() => changeLang()}>{strings.changeLang}</button>
          </div>
        </div>
      </div>
  );
}

export default Header