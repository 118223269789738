import React, { useState, useEffect } from 'react';

function Body({strings, selectedCity, passValueUp}) {
  const [data, setData] = useState([]);
  const [sortColumn, setSortColumn] = useState(0);
  const [sortOrder, setSortOrder] = useState('asc');



//   <div id="HowToButton">
//   
// </div>

  useEffect(() => {
    // Load the CSV file and parse its contents into an array of objects
    async function fetchData() {
      const response = await fetch('calendar.csv');
      const text = await response.text();
      let rows = text.split('\n');
      rows = rows.filter((str) => str !== '');
      const rowsData = rows.map(row => {
        const cells = row.split(',');
        let date = Date.parse(cells[1])
        let url = cells[0]
        let eventName = cells[2].replace(/&comma;/g, ",")
        let location = cells[3].replace(/&comma;/g, ",")
        let cancelled = cells[4]
        return {
          timestamp: date.toString(),
          date: Intl.DateTimeFormat('sv-SE', {day: "numeric", month: "numeric", hour: "numeric", minute: "numeric"}).format(date),
          eventName: eventName,
          url: url,
          location: location,
          cancelled: cancelled
        }
      });
      setData(rowsData);
    }

    fetchData();

  }, []);

  // Sort the table data based on the values in the sort column and sort order
  const sortedData = data
    .sort((rowA, rowB) => {
      let col;
      if (sortColumn === 0) {
        col = "timestamp";
      } else if (sortColumn === 1) {
        col = "eventName";
      } else if (sortColumn === 2) {
        col = "location"
      }
      const cellA = rowA[col];
      const cellB = rowB[col];
      const comparison = cellA.localeCompare(cellB, undefined, {numeric: true});
      return sortOrder === 'asc' ? comparison : -comparison;
    });

  // Render the table header row
  const headerRow = (
    <tr>
      <th onClick={() => handleSort(0)}>{strings.timeHead}</th>
      <th onClick={() => handleSort(1)}>{strings.eventHead}</th>
      <th onClick={() => handleSort(2)}>{strings.locationHead}</th>
    </tr>
  );

  const socMedia = (
    <div id="socialMedia">
      <a href="https://nyhetsbrev.kamratpostaren.se/subscription/form" rel="noreferrer" target="_blank" title={strings.newsletter}><img class="socMedImg" id="mailImg" src="assets/mail.png" alt={strings.newsletter}></img></a>
      <a href="https://www.instagram.com/kamratpostaren/" target="_blank" rel="noreferrer" title="Instagram"><img class="socMedImg" id="instaImg" src="assets/instagram.png" alt="Instagram"></img></a>
      <a href="https://radikal.social/@kamratpostaren" target="_blank" rel="noreferrer" title="Mastodon"><img class="socMedImg" id="mastodonImg" src="assets/mastodon.png" alt="Mastodon"></img></a>
      <a href="https://bsky.app/profile/kamratpostaren.se" target="_blank" rel="noreferrer" title="Bluesky"><img class="socMedImg" id="bskyImg" src="assets/bluesky.png" alt="Bluesky"></img></a>
      <a href="https://twitter.com/kamratpostaren" target="_blank" rel="noreferrer" title="Twitter"><img class="socMedImg" id="twitterImg" src="assets/twitter.png" alt="twitter"></img></a>
    </div>
  )

  // Render the table data rows
  const dataRows = sortedData.map((row, index) => {
    var tableRow = ""
    if (selectedCity.length === 0 || selectedCity === row.location || selectedCity === "allCities") {
      tableRow = (
      <tr cancelled={row.cancelled} key={index} onClick={() => openLink(row.url)}>
        <td id="dateCell">{row.date}</td>
        <td>{row.eventName}</td>
        <td>{row.location}</td>
      </tr>
      )
    }
    return tableRow;
  });


  
  const openLink = (url) => {
    window.open(url, "_blank");
  }

  // Handle sorting of the table columns
  const handleSort = (columnIndex) => {
    if (columnIndex === sortColumn) {
      // Toggle the sort order if the same column is clicked again
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // Set the sort column and default to ascending order
      setSortColumn(columnIndex);
      setSortOrder('asc');
    }
  }




  return (
    <div id="mainContent">
      <div id="socMediaSide">
        {socMedia}
      </div>
      <div id="calendarDiv">
        <table>
          <thead>{headerRow}</thead>
          <tbody>{dataRows}</tbody>
        </table>
      </div>
      <div id="socMediaBottom">
        {socMedia}
      </div>
    </div>
  );
}

export default Body;
