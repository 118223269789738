import React from 'react';

function HowTo({strings, popupToggle, passValueUp}) {


    const togglePopup = () => {
        let data = {
          "varName": "popupToggle",
          "value": !popupToggle
        }
        passValueUp([data])
      }


    return (
        <div>
            { popupToggle && (
            <div id="howToPopupOuter">
                <div id="howToPopup">
                    <div id="popupTop">
                        <p onClick={() => togglePopup()}>X</p>
                    </div>
                    <div id="howToPopupContent">
                        <p>{strings.howToText}</p>
                    </div>
                </div>
                <div id="backgroundCover" onClick={() => togglePopup()}>
                </div>
            </div>)
}
        </div>
    );
}



export default HowTo